<template>
  <div class="orderTag">
    <div class="wrap getOrder" v-if="!isOrder" @click="handleOrder">
      <a-spin v-if="loading" size="small" class="spin"></a-spin>
      <a-icon v-else type="plus"></a-icon>
      <p>关注</p>
    </div>
    <div class=" wrap cancelOrder" v-else @click="handleCancel">
      <a-spin v-if="loading" size="small" class="spin"></a-spin>
      <p>已关注</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import { Toast } from 'vant'
export default {
  components: {

  },
  props: {

    name: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters(['loginId', 'orderAuthor', 'authorData']),
    isOrder () {
      let value = false

      this.orderAuthor.forEach(item => {
        if (item.name === this.authorData.name) {
          value = true
        }
      })

      return value
    }
  },

  watch: {

  },
  created () {

  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setOrderAuthor: 'SET_ORDER_AUTHOR',
      setShowSub: 'SET_SHOW_SUB'
    }),
    ...mapActions(['GetOrder']),
    async handleOrder () {
      if (!this.loginId) {
        this.setShowSub(true)
        return
      }
      const res = await this._getOrder('POST')
      if (res.code !== 200) {
        return
      }
      this.GetOrder()
    },
    async handleCancel () {
      const res = await this._getOrder('DELETE')
      if (res.code !== 200) {
        return
      }

      this.GetOrder()
    },
    async _getOrder (method) {
      this.params = {
        dt: 'Author Profile',
        dn: this.name,
        subscriber: this.loginId,
        action: 'add'
      }

      if (method === 'DELETE') {
        this.params.action = 'del'
      }

      this.loading = true
      const res = await this.$api['auth/handleSub'](this.params)
      console.log('this.res', res)
      this.loading = false
      Toast(res.msg)
      return res
    }

  }
}
</script>

<style scoped lang="less">
.orderTag{
    line-height:20px;

    .wrap{
        padding:2px 6px;
        border:1px solid #fc8300;
        border-radius:3px;
        display:flex;
        justify-content: center;
        align-items: center;
        p{
            margin:0;
            font-size:12px;
        }
        .spin{
          position:relative;
          top:2px;
        }
    }
    .getOrder{
        display:flex;
        align-items:center;
        i{
            color:#fc8300
        }
        p{
            color:#fc8300
        }
    }
    .cancelOrder{
        border-color:#999;
    }
}
</style>
