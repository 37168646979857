<template>
  <div class="expert-detail">
    <van-nav-bar fixed title="" left-text="返回" left-arrow @click-left="onClickLeft" />
    <div v-if="item">
      <div class="item-wrap">
        <h3 class="item-title">{{ item.title }}</h3>
        <div class="item-tips-wrap">
          <div class="item-tips">
            <!-- <van-tag class="tag">原创</van-tag> -->
            <img :src="$utils.checkImg(item.avatar)" alt="" v-if="item.avatar" class="avatar" @click="goToAuthor" />
            <img src="@/assets/female.png" v-else alt="" class="avatar" @click.stop="goToAuthor(item)" />
            <span class="item-tips-author" @click="goToAuthor">{{ item.author }}</span>
            <span class="item-tips-time">{{ item.modified | expertTime }}</span>
          </div>
          <!-- <order-tag :name="item.author_profile"></order-tag> -->
        </div>
        <div class="contact-wrap">
          <div class="qrcode" @click="isShowQrcode = true">
            <img src="@/assets/qrcode.png" alt="" />
            <h3>微信客服</h3>
            <p>wholerenguru3</p>
          </div>
          <div class="phone-wrap" @click="isShowPhone = true">
            <h3>留学申请24小时咨询</h3>
            <p>美国：+1(412)756-3137</p>
            <p>中国：+86(010)5387-5758</p>
            <van-button size="mini">立即咨询</van-button>
          </div>
        </div>
        <p class="item-label" v-html="item.insight_content_cn"></p>
      </div>
      <div class="author-wrap">
        <top-header title="向专家咨询"></top-header>
        <author-list-item :author="item.author_details" style="background:#fff"></author-list-item>
      </div>
      <ExpertDetailTip :webinar="item"></ExpertDetailTip>
      <div class="tips-wrap">
        <p class="view">阅读 {{ item.pv_count }}</p>
        <div class="like-wrap" @click="handleLike">
          <i class="iconfont icon-like " :class="{ 'is-like': item.liked }"></i>
          <p class="like">{{ item.like_count }}</p>
        </div>
      </div>
      <!-- <Comment :expertId="item.name"></Comment> -->
    </div>

    <div v-else class="loading">
      <van-loading size="18px">加载中...</van-loading>
    </div>
    <van-popup v-model="isShowPhone" closeable position="bottom">
      <div class="phone-popover">
        <a href="tel:+1(412)756-3137">美国：+1(412)756-3137 </a>
        <a href="tel:+86(010)5387-5758">中国：+86(010)5387-5758</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { FlowList, Sticky, NewsItem, TopHeader } from '@/components';

import { backInitMixin } from '@/utils/mixin';
import OrderTag from './components/OrderTag';
import ExpertDetailTip from './components/ExpertDetailTip';
import { mapMutations, mapActions } from 'vuex';
import AuthorListItem from '@/components/AuthorListItem';
import Comment from './components/Comment';
export default {
  name: 'ExpertDetail',
  components: {
    FlowList,
    Sticky,
    NewsItem,
    OrderTag,
    TopHeader,
    AuthorListItem,
    Comment,
    ExpertDetailTip
  },
  mixins: [backInitMixin],
  props: {},
  data() {
    return {
      name: '',
      item: null,
      isShowPhone: false
    };
  },
  computed: {},

  watch: {},
  created() {
    console.log('created');
    this.id = this.$route.params.id;
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      filters: { name: this.id }
    };

    this.paramsPv = {
      source: 'Node',
      name: this.id
    };

    this.paramsLike = {
      source: 'Node',
      name: this.id
    };

    this._getData();
    this._getPv();
  },
  activated() {
    console.log('activated');
  },
  mounted() {
    console.log('mounted');
  },
  methods: {
    ...mapMutations({
      setAuthorData: 'SET_AUTHOR_DATA'
    }),
    ...mapActions(['GetDetailSummary']),
    goToAuthor() {
      console.log('this.item', this.item);
      this.$router.push({
        path: `/author/${this.item.author_profile}`
      });
    },
    async handleLike() {
      await this.$api['searchArea/expertLike'](this.paramsLike);
      await this._getData();
      this.$bus.$emit('handleLike', { id: this.item.name, type: !this.item.liked });
    },
    async _getData() {
      const res = await this.$api['home/authorPost'](this.params);

      if (res.message) {
        this.setAuthorData(res.message[0].author_details);
        const params = {
          name: this.id
        };
        this.GetDetailSummary(params);
        const data = res.message[0];
        let htmlCon = res.message[0].insight_content_cn;
        htmlCon = htmlCon.replace(/!important/gi, '');
        this.item = {
          ...data,
          insight_content_cn: htmlCon
        };
      }
    },
    async _getPv() {
      const res = await this.$api['searchArea/expertView'](this.paramsPv);
      console.log(res, '_getPv');
    }
  }
};
</script>

<style scoped lang="less">
p {
  margin: 0;
}
.expert-detail {
  padding-top: 46px;
  position: relative;
}
.list {
  padding: 0 16px;
}
.dot {
  width: 14px;
  height: 14px;
  border-radius: 7px;
  background: #c7d4f7;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background: #3f69e2;
  }
}
.item-wrap {
  padding: 18px 18px 0;
  .item-title {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 15px;
  }
  .item-label {
    -moz-user-select: none; /* Firefox私有属性 */
    -webkit-user-select: none; /* WebKit内核私有属性 */
    -ms-user-select: none; /* IE私有属性(IE10及以后) */
    -khtml-user-select: none; /* KHTML内核私有属性 */
    -o-user-select: none; /* Opera私有属性 */
    user-select: none; /* CSS3属性 */
  }
  .item-tips-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    .item-tips {
      flex: 1;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;
      color: #999;
      align-items: center;
      // color:rgba(0,0,0,.3);
      .tag {
        background: #f2f2f2;
        // color:rgba(0,0,0,.3)
        color: #666;
        height: 20px;
        margin-right: 15px;
        min-width: 30px;
      }
      .avatar {
        width: 26px;
        height: 26px;
        border-radius: 13px;
      }
      .item-tips-author {
        margin: 0 10px;
        color: #4169e2;
        font-size: 14px;
      }
    }
  }
  .contact-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 2px 2px 5px #ccc;
    .qrcode {
      padding: 5px 20px;
      background: #f4f4f4;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 60%;
      }
      h3 {
        margin: 0;
        margin-top: 5px;
        font-size: 14px;
      }
      p {
        font-size: 12px;
      }
    }
    .phone-wrap {
      flex: 1;
      background: #1aa56e;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      h3 {
        margin: 0;
        margin-top: 5px;
        font-size: 14px;
        color: #fff;
      }

      p {
        margin-top: 5px;
        font-size: 12px;
        color: #fff;
      }
      .van-button {
        margin-top: 5px;
      }
    }
  }
}

.author-wrap {
  background: #fff;
  padding: 10px 18px;
}

.tips-wrap {
  padding: 0 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
  .view {
  }
  .like-wrap {
    display: flex;
    flex-direction: row;
    width: 40px;
    justify-content: space-between;
    align-items: center;
    i {
      color: #666;
    }
    .is-like {
      color: #fc8300;
    }
  }
}
.phone-popover {
  padding: 30px 15px;
  display: flex;
  flex-direction: column;
  a {
    height: 50px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    justify-content: center;
    align-items: center;
    &:nth-child(2) {
      border: none;
    }
  }
}
.loading {
  margin-top: 20px;
}
/deep/ .item-label img {
  width: 100% !important;
  height: auto !important;
}
/deep/ .item-label span {
  width: 100% !important;
  height: auto !important;
}
/deep/ .item-label section {
  width: 100% !important;
}
/deep/ .van-pull-refresh {
  overflow: visible;
}
/deep/ .ant-timeline-item-tail {
  border-left-color: #c7d4f7;
}
/deep/ .ant-timeline-item-last > .ant-timeline-item-tail {
  display: block;
}
</style>
