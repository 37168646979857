<template>
  <div class="blog-detail-tip">
    <div class="qrcode-wrap">
      <div class="qrcode-item wechat">
        <div class="qrcode-con">
          <img
            src="https://www.wholeren.com/wp-content/uploads/2017/05/qrcode_for_gh_657b617e24ce_430-1-2.jpg
"
            alt=""
          />
          <div>
            <p>微信公众号</p>
            <h3>陈航说留美</h3>
          </div>
        </div>
      </div>

      <div class="qrcode-item xigua">
        <div class="qrcode-con">
          <img src="https://www.wholeren.com/wp-content/uploads/2021/06/西瓜二维码.png" alt="" />
          <div>
            <p>西瓜视频</p>
            <h3>陈航说留美</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="webinar-wrap">
      <TopHeader title="专家讲座" right-text="查看更多" @handleRight="goToWebinar"></TopHeader>
      <div class="webinar-image" @click="showBigImage">
        <img :src="webinar.attach_image" alt="" />
      </div>
    </div> -->
    <div class="recommend-wrap">
      <TopHeader title="推荐阅读"></TopHeader>
      <div>
        <p v-for="item in recommendRead" :key="item.name" @click="goToRecommend(item)">{{ item.title }}</p>
      </div>
    </div>

    <div class="prev-next-wrap">
      <div class="prev">
        <div @click="goToPrev" v-if="summary.pre_id">上一篇</div>
      </div>
      <div class="next">
        <div @click="goToNext" v-if="summary.next_id">下一篇</div>
      </div>
    </div>
  </div>
</template>

<script>
import { TopHeader } from '@/components';
import { mapState } from 'vuex';
import { ImagePreview } from 'vant';
export default {
  components: { TopHeader },
  props: {
    webinar: {
      type: Object,
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      summary: state => state.blog.detailSummary
    }),
    recommendRead() {
      return this.summary.recommends || [];
    }
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    showBigImage() {
      ImagePreview({
        images: [this.webinar.attach_image]
      });
    },
    goToRecommend(item) {
      this.$router.replace({
        path: `/expert/${item.name}`
      });
    },
    goToPrev() {
      this.$router.replace({
        path: `/expert/${this.summary.pre_id}`
      });
    },
    goToNext() {
      this.$router.replace({
        path: `/expert/${this.summary.next_id}`
      });
    },
    goToWebinar() {
      this.$router.push('/info/webinar');
    }
  }
};
</script>

<style scoped lang="less">
.blog-detail-tip {
  padding: 0 15px;
  .qrcode-wrap {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .qrcode-item {
      flex: 1;

      padding: 15px 10px;
      background-image: url('~@/assets/blog/bg-blue.png');
      border-radius: 5px;
      background-size: 100% 100%;
      .qrcode-con {
        background: #fff;
        display: flex;
        align-items: center;
        border-radius: 5px;
        img {
          width: 65px;
          height: 65px;
          margin-right: 5px;
        }
        p {
          font-size: 12px;
          color: #333;
          text-align: center;
        }
        h3 {
          margin: 0;
          font-size: 13px;
          color: #333;
        }
      }

      &:nth-child(2) {
        margin-left: 6px;
        background-image: url('~@/assets/blog/bg-green.png');
      }
    }
  }
  .webinar-wrap {
    margin-top: 20px;
    .webinar-image {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      img {
        width: 70%;
      }
    }
  }
  .recommend-wrap {
    margin-top: 20px;
    p {
      font-size: 14px;
      text-decoration: underline;
      margin-bottom: 5px;
    }
  }
  .prev-next-wrap {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    color: #4169e2;
  }
}
</style>
