<template>
  <div class="comment">
    <div>
      <div class="comment-top" v-if="list.length">
        <p class="top-label">精选留言</p>
        <p class="top-comment" @click="showComment">写留言</p>
      </div>

      <div class="con-wrap" v-for="itemData in list " :key="itemData.name">
        <div class="con-comment-wrap">
          <p class="con-author">{{ itemData.author | authorHide }}</p>
          <p class="con-comment">{{ itemData.body }}</p>
        </div>
        <div class="con-reply-wrap">
          <div class="con-reply-item" v-for="(reply,replyIndex) in itemData.replies" :key="reply.name">
            <p class="con-reply-author" v-if="reply.owner==='Guest'">{{ reply.author | authorHide }}</p>
            <p class="con-reply-author" v-else>{{ reply.author }}</p>
            <p class="con-reply">{{ reply.body }}</p>
            <p class="reply-handle" @click="showReply(itemData)" v-if="replyIndex===(itemData.replies.length-1)&&itemData.author===loginId&&reply.author!==loginId">回复</p>
          </div>
        </div>
      </div>

      <div v-if="!list.length" class="addComment" @click="showComment">
        写留言
      </div>
    </div>

    <van-action-sheet v-model="show">
      <div class="modal-wrap">
        <div class="modal-top">
          <a-icon type="close" @click="cancel"></a-icon>
          <p class="modal-title">写留言</p>
          <a-button type="primary" :disabled="!addComment" class="modal-btn" @click="submitComment">提交</a-button>
        </div>
        <div class="modal-input-wrap">
          <a-textarea class="modal-input" placeholder="留言被精选后，将对所有人可见" allow-clear v-model="addComment" />
        </div>
      </div>
    </van-action-sheet>
    <van-action-sheet v-model="showRep">
      <div class="modal-wrap">
        <div class="modal-top">
          <a-icon type="close" @click="cancel"></a-icon>
          <p class="modal-title">写回复</p>
          <a-button type="primary" :disabled="!addReply" class="modal-btn" @click="submitReply">提交</a-button>
        </div>
        <div class="modal-input-wrap">
          <a-textarea class="modal-input" placeholder="添加回复" allow-clear v-model="addReply" />
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>
import { FlowList } from '@/components'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    FlowList
  },
  props: {
    expertId: {
      default: '',
      type: String
    }
  },
  data () {
    return {
      list: [],
      show: false,
      showRep: false,

      addComment: '',
      addReply: ''
    }
  },
  computed: {
    ...mapGetters(['loginId'])
  },
  watch: {

  },
  created () {
    this.params = {
      limit_start: 0,
      limit_page_length: 10,
      reference_doctype: 'nCov Node',
      reference_name: this.expertId
    }
    this._getData()
  },
  mounted () {

  },
  methods: {
    ...mapMutations({
      setShowSub: 'SET_SHOW_SUB'
    }),
    handleData (data) {
      this.list = data
    },
    showComment () {
      if (!this.loginId) {
        this.$toast({
          duration: 1500, // 持续展示 toast

          message: '请先完成订阅，再进行评论',
          onClose: () => {
            this.setShowSub(true)
          }
        })

        return
      }

      this.show = true
    },
    cancel () {
      this.show = false
      this.addComment = ''
    },
    async submitComment () {
      var fd = new FormData()
      fd.append('author', this.$enc(this.loginId))
      fd.append('reference_doctype', 'nCov Node')
      fd.append('reference_name', this.expertId)
      fd.append('body', this.addComment)

      const res = await this.$api['searchArea/addComment'](fd)
      if (res.message && res.message.body) {
        this.$toast({
          type: 'success',
          duration: 1500, // 持续展示 toast

          message: '评论成功',
          onClose: () => {
            this.show = false
          }
        })
      }
    },
    showReply (item) {
      this.item = item
      if (!this.loginId) {
        this.$toast({
          duration: 1500, // 持续展示 toast

          message: '请先完成订阅，再进行回复',
          onClose: () => {
            this.setShowSub(true)
          }
        })

        return
      }

      this.showRep = true
    },
    async submitReply () {
      var fd = new FormData()
      fd.append('author', this.$enc(this.loginId))
      fd.append('to_author', this.item.replies[0].author)
      fd.append('parent', this.item.name)
      fd.append('body', this.addReply)

      const res = await this.$api['searchArea/addReply'](fd)
      if (res.message && res.message.body) {
        this.$toast({
          type: 'success',
          duration: 1500, // 持续展示 toast

          message: '回复成功',
          onClose: () => {
            this.showRep = false
            this._getData()
          }
        })
      }
    },
    async _getData () {
      const res = await this.$api['searchArea/comment'](this.params)

      if (res.message) {
        this.list = res.message
      }
    }
  }
}
</script>

<style scoped lang="less">
p{
    margin:0
}
.comment{
    background:#eee;
    padding-bottom:30px;
    .comment-top{
        padding:0 18px;
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        height:50px;
        align-items: center;
        .top-label{
            color:#666
        }
        .top-comment{
            color:@primary-color
        }
    }
    .con-wrap{
        padding:0 18px;
        .con-comment-wrap{
            .con-author{
                font-size:12px;
                color:#666;
            }
            .con-comment{
                margin-top:5px;
                font-size:14px;
                color:#333
            }
        }
        .con-reply-wrap{
            margin-top:10px;
            padding-left:10px;
            border-left:2px solid #ccc;
            .con-reply-item{
                margin-bottom:10px;
                display:flex;
                flex-direction: column;

                position:relative;
                .con-reply-author{
                    font-size:12px;
                    color:#666;
                }
                .con-reply{
                    margin-top:5px;
                    font-size:14px;
                    color:#333
                }
                .reply-handle{
                    position:absolute;
                    right:20px;
                    top:20px;
                    color:@primary-color;
                }
            }
        }
    }
    .addComment{
        height:80px;
        line-height:80px;
        text-align:center;
        color:@primary-color;
        font-weight:bold;
    }

}
.modal-wrap{
    background:#ddd;
    padding-bottom:150px;
    .modal-top{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
        height:50px;
        align-items: center;
        padding:20px;
        .modal-title{
            color:#333;
        }

    }
    .modal-input-wrap{
        padding:20px;
        .modal-input{
            height:200px;
            background: #ddd;
            border:0;
            color:#000;
            &:focus{
                box-shadow: none;
            }
            &::placeholder {
                color: #999;

            }
        }
    }

}
/deep/ .ant-btn-primary[disabled]{
    background:#ccc!important;
    color:#666;
}
</style>
